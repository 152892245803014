import React from 'react'
import {  Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins',
    fontSize:'20px'
   },
 }));

export default function About() {
    const classes = useStyles();
    return (
        <div id="about" style={{backgroundColor: '#d6d9dd', paddingBottom: '50px'}}>    
            <Container maxWidth="md" component="main" >
                <Typography  align="center" className={classes.pagetitle} gutterBottom>
                About Us
                </Typography>   
                <Typography  className={classes.pagesubtitle}>
                The Tyler Tools brand of hoist products and rigging tools are of proven designs and high quality. Hoist products meet or exceed ASME standards and the heads of the tool products are forged from chromoly vanadium hardened steel, or precision waterjet cut from ultra-hardened AR500 Steel. 
             </Typography>              
             </Container>   
        </div>
    )
}
