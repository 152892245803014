import './App.css';
import React from "react";
import {  BrowserRouter as Router,  Switch,  Route} from "react-router-dom";
import wireRope from './components/wireRope';
import cable from './components/cable';
import Home from './components/Home';
import vinylcoat from './components/vinylCoatedCable';
import Privacy from './components/Privacy'
import Termsofuse from './components/Termsofuse'
import Contactform from './components/Contactform'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from './components/Appbar'
import  Box   from '@material-ui/core/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

 function App() {
  return (
    <div>
       <ScrollToTop /> 
       <Navbar/>   
      <Box display={{ md: 'block', lg: 'none' }} ><Drawer/></Box> 
      <Box display={{ xs: 'none', sm: 'block', md: 'none' }}><br/><br/></Box>
       <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/contactform" component={Contactform}></Route>
        <Route path="/privacypolicy" component={Privacy}></Route>
        <Route path="/termsofuse" component={Termsofuse}></Route>
        <Route path="/galvanizedwirerope" component={wireRope}></Route>
        <Route path="/galvanizedcable" component={cable}></Route>
        <Route path="/vinylcoatedcable" component={vinylcoat}></Route>
      </Switch>
      <Footer/>
    </div>
  );
}


export default App;
