import React from 'react'
import {  Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import wtb1 from './../assets/wtb1.jpg';
import wtb2 from './../assets/wtb2.jpg';
import wtb3 from './../assets/wtb3.jpg';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins',
    fontSize:'20px'
   },
   img:{
       height: '200px',
       border: '3px solid black',
       '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
        border: '3px solid #00be00',
       }
   }
 }));

export default function Wheretobuy () {
    const classes = useStyles();
    return (
        <div id="wheretobuy" style={{paddingBottom: '150px', paddingTop: '150px', backgroundColor: '#d6d9dd'}}>    
            <Container maxWidth="lg" component="main" >
                <Typography  align="center" className={classes.pagetitle} gutterBottom>
                Where to Buy
                </Typography>   
            <Grid container direction="row" spacing={6} justify="center" alignItems="center" >           
                {/* <Grid item md={4} xs={12} style={{flexBasis:'0'}} >
                <a href="https://www.e-rigging.com/Synthetic-Lifting-Slings_c_3879.html" target="_blank">
                    <img src ={wtb3} className={classes.img} alt=""/></a>
                </Grid>
                <Grid item md={4} xs={12} style={{flexBasis:'0'}}>
                <a href="https://www.amazon.com/s?k=Thor-Tex" target="_blank">
                    <img src ={wtb1} className={classes.img} alt=""/></a>
                </Grid>   
                <Grid item md={4} xs={12} style={{flexBasis:'0'}}>
                <a href="https://sealand-e1q0.onrender.com/contactform" target="_blank">
                    <img src ={wtb2} className={classes.img} alt=""/></a>
                </Grid> */}
            </Grid>            
             </Container>   
        </div>
    )
}
