import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.svg'
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Grid}  from '@material-ui/core';

const useStyles = makeStyles({
logo: {
    height: '75px',
    margin: '10px',
    marginTop: '20px',
    marginLeft: '30px',
},
toplink: { 
  fontFamily: 'Poppins',
  fontSize: '20px',
  textDecoration: 'none',
  color: 'black',  
  margin: 'auto',
  padding:'10px',
'&:hover': {
  background: "lightgrey",
},
},
});

function Test2 () { 
  const classes = useStyles();

  return (
    <React.Fragment>   
         <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>  
         <Grid  container  direction="row"  justify="space-around"  alignItems="center" style={{backgroundColor: '#e4e4e4'}}>
      <Grid container item lg={5} md={5}>
      <Link to="/">
             <img src={logo} className={classes.logo} alt=""/></Link>
      </Grid>
      <Grid container item lg={7} md={7} justify="flex-end">  
          <Typography> 
          <a href="/#products" className={classes.toplink}>Products</a></Typography>
          <Typography>
           <a href="/#wheretobuy" className={classes.toplink}>Where to Buy</a></Typography>
           <Typography> 
          <a href="/#about" className={classes.toplink}>About</a></Typography>
          <Typography variant = "subheading" className = {classes.padding} color="inherit" > 
          <a href="/#contact" className={classes.toplink}>Contact</a></Typography>
          <Typography>
          <Link to="/" className={classes.toplink}>Home</Link></Typography>
      </Grid>
      </Grid>      
      </Box>  
      {/* mobile  */}
      <Box	display={{ xs: 'block', sm: 'none' }} > 
      <div align="center" style={{marginTop: '55px'}}>               
      </div>        
      </Box>      
    </React.Fragment>
  );
};

export default Test2;